import { createTheme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: blueGrey,
  },
  typography: {
    fontFamily: [
      'Titillium Web'
    ].join(','),
  },
});
